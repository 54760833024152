let config = () => {
    switch (process.env.NODE_ENV) {
        case("production"):
            return {
                accountUrl: "https://vsnmswolba.execute-api.us-east-1.amazonaws.com/prod",
                licenseUrl: "https://vsnmswolba.execute-api.us-east-1.amazonaws.com/prod",
                userPoolId: "us-east-1_eIN56gn6k",
                clientId: "68ai9jd4htsrot7agebmlmucor"
            };
        case("development"):
            return {
                accountUrl: "https://03fseyk3k2.execute-api.us-east-1.amazonaws.com/dev",
                licenseUrl: "https://03fseyk3k2.execute-api.us-east-1.amazonaws.com/dev",
                userPoolId: "us-east-1_biWSzzYYh",
                clientId: "6j0a7366v0od9eb8ashsjhd4sp"
            };
        default:
            return {
                accountUrl: "https://03fseyk3k2.execute-api.us-east-1.amazonaws.com/dev",
                licenseUrl: "https://03fseyk3k2.execute-api.us-east-1.amazonaws.com/dev",
                userPoolId: "us-east-1_biWSzzYYh",
                clientId: "6j0a7366v0od9eb8ashsjhd4sp"
            };
    }
}

export default config;