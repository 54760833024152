import React from 'react';
import {dateStrToShortDate} from "../utility";
import {DataGrid} from "@material-ui/data-grid";

let columns = [
    {field: 'customerId', headerName: 'Customer ID', width: 150},
    {field: 'cloudifyVersion', headerName: 'Version', width: 80},
    {field: 'licenseEdition', headerName: 'Edition', width: 90},
    {field: 'expirationDate', headerName: 'Expiration Date', width: 130},
    {field: 'trial', headerName: 'Is Trial', width: 80},
    {field: 'createdBy', headerName: 'Created By', width: 150},
    {field: 'createdAt', headerName: 'Created At', width: 110},
    {field: 'updatedAt', headerName: 'Updated At', width: 110},

];

let LicensesTable = (props) => {
    let licenses = (<h1>No Licenses</h1>);
    if (props.licenses) {
        let processedLicenses = props.licenses.map(license => {
            return {
                id: license.id,
                customerId: license.customerId,
                cloudifyVersion: license.cloudifyVersion,
                licenseEdition: license.licenseEdition,
                expirationDate: license.expirationDate,
                trial: license.trial,
                createdBy: license.createdBy,
                updatedAt: dateStrToShortDate(license.updatedAt),
                createdAt: dateStrToShortDate(license.createdAt)
            }
        });
        let licensesTable = <DataGrid
            rows={processedLicenses}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick={true}
            onRowClick={(param) => props.onLicenseClick(param.data.id)}
        />
        licenses = (
            <div style={{height: 400, width: '100%'}}>
                    {licensesTable}
            </div>
        );
    }
    return (
        <>
            {licenses}
        </>
    );
};

export default LicensesTable;