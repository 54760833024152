let admins =  [
    "alex@cloudify.co",
    "ofer@cloudify.co",
    "yoni@cloudify.co",
    "sandy@cloudify.co",
    "mohammeda@cloudify.co",
    "jakub.niezgoda@cloudify.co",
    "jakub.madej@cloudify.co",
    "lukasz.maksymczuk@cloudify.co",
    "mateusz@cloudify.co",
    "michaelg@cloudify.co",
    "jonathan@cloudify.co",
    "trammell@cloudify.co",
    "adar@cloudify.co",
    "jonathan@cloudify.co"
];

export default admins;