import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export default function DropDown(props) {
    let items = props.elementConfig.options.map(item => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)
    return (
        <>
            <InputLabel id="demo-simple-select-outlined-label">{props.elementConfig.label}</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={props.value}
                onChange={props.changed}
                variant="outlined"
                fullWidth
                {...props.elementConfig}
            >
                {items}
            </Select>
        </>
    );
}