import React, {Component} from 'react'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import * as licenseActions from '../store/actions';

let styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 500,
    },
    table: {
        minWidth: 650,
    }
});


class Licenses extends Component {
    componentDidMount() {
        this.props.onInitLicenses();
    }

    deleteHandler = (id) => {

    };

    render() {
        let classes = {
            table: {}
        };

        let licenses = <p> Something went wrong</p>
        if (!this.props.error) {
            if (this.props.licenses) {
                licenses = (
                    <Grid direction="column" container>
                        <Grid item>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="right">Account ID</TableCell>
                                            <TableCell align="right">Updated At</TableCell>
                                            <TableCell align="right">Created AT</TableCell>
                                            <TableCell align="right">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.licenses.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="right">{row.account_id}</TableCell>
                                                <TableCell align="right">{row.updatedAt}</TableCell>
                                                <TableCell align="right">{Date(row.createdAt)}</TableCell>
                                                <TableCell align="right">
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => this.deleteHandler(row.id)}
                                                    >
                                                        Delete
                                                    </Button>
                                                    <Link to={"/licenses/" + row.id}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => this.deleteHandler(row.id)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                )
            } else {
                licenses = <h1>No Licenses</h1>
            }
        }
        return (
            licenses
        )
    }
}


let mapStateToProps = state => {
    return {
        licenses: state.licenses.licenses,
        error: state.licenses.error,
        loading: state.licenses.loading
    }
}

let mapDispatchToProps = dispatch => {
    return {
        onInitLicenses: () => dispatch(
            licenseActions.initLicenses()
        )
    }
}
export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Licenses);
