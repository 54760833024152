import React, {Component} from "react";

import {DataGrid} from '@material-ui/data-grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {compose} from 'recompose';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import * as accountsActions from "../store/actions"
import Loader from "../containers/Loader";
import Alert from "@material-ui/lab/Alert";
import Typography from '@material-ui/core/Typography';
import NewAccount from "./NewAccount";
import {withRouter} from "react-router";
import {dateStrToShortDate} from "../utility";

let styles = (theme) => ({
    absolute: {
        position: 'fixed',
        bottom: 16,
        right: 24,
    },
});

let columns = [
    {field: 'id', headerName: 'ID', width: 300},
    {field: 'name', headerName: 'Name', width: 240},
    {field: 'createdAt', headerName: 'Created At (MM/DD/YYY)', width: 240},
    {field: 'updatedAt', headerName: 'Updated At (MM/DD/YYY)', width: 240},
];

class AccountsManager extends Component {
    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    componentDidMount() {
        this.props.onInitAccounts();
    }

    render() {
        let loader = null;
        if (this.props.loading) {
            loader = <Loader/>
        }
        let error = null;
        if (this.props.error) {
            error = <Alert severity="error">{this.props.error}</Alert>
        }
        let classes = this.props.classes;
        let accounts = <Typography variant="h1">No Accounts were found</Typography>
        if (this.props.accounts != null && this.props.accounts.length > 0) {
            let processedAccounts = this.props.accounts.map(account => {
                return {
                    id: account.id,
                    name: account.name,
                    updatedAt: dateStrToShortDate(account.updatedAt),
                    createdAt: dateStrToShortDate(account.createdAt)
                }
            });
            accounts = <DataGrid
                rows={processedAccounts}
                columns={columns}
                pageSize={5}
                disableSelectionOnClick={true}
                onRowClick={(param) => this.props.history.push("/accounts/" + param.data.id)}/>
        }
        let addAccountFab = null;
        if (this.props.isLoggedUserAdmin) {
            addAccountFab = (
                <>
                    <Fab color="secondary" className={classes.absolute} onClick={this.handleClickOpen}>
                        <AddIcon/>
                    </Fab>
                    <NewAccount open={this.state.open} handleClickOpen={this.handleClickOpen}
                                handleClose={this.handleClose}/>
                </>
            )
        }
        return (
            <div style={{height: 400, width: '100%'}}>
                {loader}
                {error}
                {accounts}
                {addAccountFab}
            </div>

        )
    }
}

let mapStateToProps = state => {
    return {
        accounts: state.accounts.accounts,
        loading: state.accounts.loading,
        error: state.accounts.error,
        isLoggedUserAdmin: state.auth.isAdmin
    }
};

let mapDispatchToProps = dispatch => {
    return {
        onInitAccounts: () => dispatch(
            accountsActions.initAccounts()
        )
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(AccountsManager);