import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Loader from "../containers/Loader";
import axios from 'axios'
import {Redirect} from "react-router-dom";
import Input from '../containers/UI/Input';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import * as licenseActions from '../store/actions/license';
import * as accountActions from '../store/actions/account';
import {inputChangedHandler} from '../utility';

let BY_VERSION = "by_version";
let BY_EXPIRATION_DATE = "by_expiration_date";
let BASIC = "basic";

class NewLicense extends Component {
    state = {
        controls: {
            licenseType: {
                elementType: "option",
                elementConfig: {
                    label: "Version Type",
                    options: [
                        {"key": BY_VERSION, value: "By Version"},
                        {"key": BY_EXPIRATION_DATE, value: "By Expiration Date"},
                        {"key": BASIC, value: "Basic"}
                    ]
                },
                value: 'basic',
                visible: true,
                valid: true,
                errorMessages: null
            },
            accountId: {
                elementType: "input",
                elementConfig: {
                    type: "text",
                    label: "Account Id"
                },
                value: '',
                validation: {
                    required: true
                },
                visible: false,
                valid: true,
                errorMessages: null
            },
            capabilities: {
                elementType: "input",
                elementConfig: {
                    type: "text",
                    label: "Capabilities"
                },
                value: "",
                visible: false,
                valid: true,
                errorMessages: null
            },
            version: {
                elementType: "input",
                elementConfig: {
                    type: "text",
                    label: "Version"
                },
                value: "",
                visible: true,
                valid: true,
                errorMessages: null
            },
            customerId: {
                elementType: "input",
                elementConfig: {
                    type: "text",
                    label: "Customer ID"
                },
                value: "",
                validation: {
                    required: true
                },
                valid: true,
                errorMessages: null
            },
            licenseEdition: {
                elementType: "drop-down",
                elementConfig: {
                    label: "License Edition",
                    options: [
                        {"key": "Premium", value: "Premium"}
                    ]
                },
                value: "Premium",
                valid: true,
                errorMessages: null
            },
            expirationDate: {
                elementType: "date",
                elementConfig: {
                    label: "Expiration Date",
                    minDate: new Date(),
                    maxDate: new Date()
                },
                value: new Date(),
                validation: {
                    required: true
                },
                valid: true,
                visible: true,
                errorMessages: null
            },
            trial: {
                elementType: "checkbox",
                elementConfig: {
                    label: "Is Trial?"
                },
                value: false,
                valid: true,
                errorMessages: null
            }
        },
        trial: false,
        formIsValid: false
    };

    handleLicenseEdition = (value) => {
        if ( value === 'Spire') {
            setTimeout(() => this.addSpireOptionToLicenseEdition(), 20);
        }
        setTimeout(() => this.inputChangedHandler({target: {value: value}}, "licenseEdition"), 20);
    }

    addSpireOptionToLicenseEdition = () => {
        let updatedLicenseForm = {
            ...this.state.controls
        };
        let updatedExpirateDateElement = {
            ...updatedLicenseForm['licenseEdition']
        };
        updatedExpirateDateElement.elementConfig.options.push({key: "Spire", value: 'Spire'});
        updatedLicenseForm['licenseEdition'] = updatedExpirateDateElement;
        this.setState({controls: updatedLicenseForm});
    }

    componentDidMount() {
        let accountId = this.props.match.params["accountId"]
        if (accountId) {
            this.inputChangedHandler({target: {value: accountId}}, "accountId");
            this.props.getAccountById(accountId)
        }
        if ('version' in this.props.account) {
            this.inputChangedHandler({target: {value: this.props.account.version}}, "version");
        }
        setTimeout(() => this.inputChangedHandler({target: {value: this.props.account.customerId}}, "customerId"), 10);

        this.handleLicenseEdition(this.props.account.licenseEdition);
        setTimeout(() => this.inputChangedHandler({target: {value: this.props.account.id}}, "accountId"), 30);
        setTimeout(() => this.updateExpirationMaxDate(this.props.account.expirationDate), 40);
    }

    postHandlerData = () => {
        let license = null
        switch (this.state.controls.licenseType.value) {
            case(BY_VERSION):
                license = {
                    type: this.state.controls.licenseType.value,
                    accountId: this.state.controls.accountId.value,
                    capabilities: null,
                    cloudifyVersion: this.state.controls.version.value,
                    customerId: this.state.controls.customerId.value,
                    licenseEdition: this.state.controls.licenseEdition.value,
                    trial: this.state.controls.trial.value
                };
                break;
            case(BY_EXPIRATION_DATE):
                license = {
                    type: this.state.controls.licenseType.value,
                    accountId: this.state.controls.accountId.value,
                    capabilities: null,
                    customerId: this.state.controls.customerId.value,
                    licenseEdition: this.state.controls.licenseEdition.value,
                    expirationDate: this.state.controls.expirationDate.value,
                    trial: this.state.controls.trial.value
                };
                break;
            case(BASIC):
            default:
                license = {
                    type: this.state.controls.licenseType.value,
                    accountId: this.state.controls.accountId.value,
                    capabilities: null,
                    cloudifyVersion: this.state.controls.version.value,
                    customerId: this.state.controls.customerId.value,
                    licenseEdition: this.state.controls.licenseEdition.value,
                    expirationDate: this.state.controls.expirationDate.value,
                    trial: this.state.controls.trial.value
                }
        }
        let accountId = this.props.match.params["accountId"];
        this.props.onCreateLicense(accountId, license);
    };

    changeFormByLicenseType = () => {
        switch (this.state.controls.licenseType.value) {
            case(BY_VERSION):
                setTimeout(() => this.updateVisibility('version', true),
                    10);
                setTimeout(() => this.updateVisibility('expirationDate', false),
                    20);
                break;
            case(BY_EXPIRATION_DATE):
                setTimeout(() => this.updateVisibility('version', false),
                    10);
                setTimeout(() => this.updateVisibility('expirationDate', true),
                    20);
                break;
            case(BASIC):
            default:
                setTimeout(() => this.updateVisibility('version', true),
                    10);
                setTimeout(() => this.updateVisibility('expirationDate', true),
                    20);
                break;
        }
    }

    updateVisibility = (elementIdentifier, isVisible) => {
        let updatedLicenseForm = {
            ...this.state.controls
        };
        let updatedElement = {
            ...updatedLicenseForm[elementIdentifier]
        };
        updatedElement.visible = isVisible;
        updatedLicenseForm[elementIdentifier] = updatedElement;
        this.setState({controls: updatedLicenseForm});
    }

    updateExpirationMaxDate = (maxDate) => {
        let updatedLicenseForm = {
            ...this.state.controls
        };
        let updatedExpirateDateElement = {
            ...updatedLicenseForm['expirationDate']
        };
        updatedExpirateDateElement.elementConfig.maxDate = maxDate;
        updatedLicenseForm['expirationDate'] = updatedExpirateDateElement;
        this.setState({controls: updatedLicenseForm});
    }

    inputChangedHandler = (event, inputIdentifier) => {
        let newState = inputChangedHandler(this.state.controls, event, inputIdentifier)
        this.setState(newState);
        if (inputIdentifier === "licenseType") {
            setTimeout(() => this.changeFormByLicenseType(event.target.value), 10);
        }
    }

    render() {
        let loader = <Grid item></Grid>
        if (this.props.loading) {
            loader = (<Grid item>
                <Loader/>
            </Grid>)
        }
        let redirect = null;
        if (this.props.submitted === true) {
            let accountId = this.props.match.params["accountId"]
            redirect = <Redirect to={"/accounts/" + accountId}/>
        }

        const formElementArray = []
        for (let key in this.state.controls) {
            if (this.state.controls[key]['visible'] != null) {
                if (this.state.controls[key].visible === true) {
                    formElementArray.push({
                        id: key,
                        config: this.state.controls[key]
                    });
                }
            } else {
                formElementArray.push({
                    id: key,
                    config: this.state.controls[key]
                });
            }

        }

        return (
            <>
                <Grid container direction="column" spacing={1}>
                    {loader}
                    {redirect}
                    {
                        formElementArray.map(formElement =>
                            <Grid item key={formElement.id}>
                                <Input
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    changed={(event) => this.inputChangedHandler(event, formElement.id)}
                                    invalid={!formElement.config.valid}
                                    errorMessages={formElement.config.errorMessages}
                                />
                            </Grid>
                        )
                    }
                    <Grid>
                        <Button variant="contained" color="primary" onClick={this.postHandlerData}
                                disabled={!this.state.formIsValid}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        license: state.license.license,
        error: state.license.error,
        submitted: state.license.submitted,
        purchased: state.license.purchased,
        account: state.account.account
    };
};

let mapDispatchToProps = dispatch => {
    return {
        onCreateLicense: (accountId, licenseData) => dispatch(
            licenseActions.createLicense(accountId, licenseData)
        ),
        getAccountById: (accountId) => dispatch(
            accountActions.initAccount(accountId)
        )
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(NewLicense, axios);