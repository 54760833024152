import * as actionTypes from './actionTypes';
import axios_accounts from '../../axios-accounts';
import axios_licenses from '../../axios-licenses';
import config from "../../config";

import {AuthenticationDetails, CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";

let poolData = {
    UserPoolId: config().userPoolId,
    ClientId: config().clientId
};

let userPool = new CognitoUserPool(poolData);

let getAuthenticatedUser = () => {
    return userPool.getCurrentUser();
};

export let authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export let authSuccess = (authData) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        authData: authData,

    };
};

export let authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export let onLogoutInnit = () => {
    let user = getAuthenticatedUser();
    if (user) {
        user.signOut();
    }
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('email')
    return dispatch => {
        dispatch(logout());
    }
}

export let logout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export let checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('expirationDate');
            localStorage.removeItem('email');
            dispatch(logout())
        }, expirationTime * 1000) //in meliseconds
    }
};

export let auth = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        let authData = {
            Username: username,
            Password: password
        };
        let authDetails = new AuthenticationDetails(authData)
        let userData = {
            Username: username,
            Pool: userPool
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authDetails, {
            onSuccess(result) {
                axios_accounts.defaults.headers.common['Authorization'] = result.idToken.jwtToken
                axios_licenses.defaults.headers.common['Authorization'] = result.idToken.jwtToken
                let expiresIn = result.idToken.payload.exp - result.idToken.payload.auth_time;
                localStorage.setItem('token', result.idToken.jwtToken);
                localStorage.setItem('expirationDate', new Date().getTime() + (expiresIn * 1000));
                localStorage.setItem('email', result.getIdToken().payload.email)


                dispatch(
                    authSuccess(
                        {
                            token: result.idToken.jwtToken,
                            expiresIn: expiresIn,
                            userId: result.idToken.payload,
                            email: result.getIdToken().payload.email
                        }
                    )
                );
                dispatch(checkAuthTimeout(expiresIn));
            },
            onFailure(error) {
                dispatch(authFail(error.message));
            }
        });
    }
};

export let authCheckState = () => {
    return dispatch => {
        let token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            let storedDate = parseInt(localStorage.getItem('expirationDate'));
            let expirationDate = new Date(storedDate);
            let now = new Date();
            if (expirationDate > now) {
                axios_accounts.defaults.headers.common['Authorization'] = token
                axios_licenses.defaults.headers.common['Authorization'] = token
                let email = localStorage.getItem('email');
                dispatch(authSuccess({
                    token: token,
                    expirationDate: expirationDate,
                    email: email
                }));
            } else {
                dispatch(logout());
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
            }
        }
    };
};