import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

let initialState = {
    licenses: null,
    error: false,
    loading: false
};

let licenses = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LICENSES_START:
            return fetchLicensesStart(state, action);
        case actionTypes.SET_LICENSES:
            return setLicenses(state, action);
        case actionTypes.FETCH_LICENSES_FAILED:
            return fetchLicensesFailed(state, action);
        default:
            return state
    }
};

let fetchLicensesStart = (state, action) => {
    return updateObject(state, {
        loading: action.loading,
        error: action.error
    })
};

let setLicenses = (state, action) => {
    return updateObject(state, {
        licenses: action.licenses,
        error: false,
        loading: false
    });
};

let fetchLicensesFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

export default licenses;
