import React from 'react';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import DatePicker from "./DatePicker";
import DropDown from "./DropDown";
import RadioButtonGroup from "./RadioButtonGroup"
let Input = (props) => {
    let inputElement = null
    let helperText = null;
    helperText = props.errorMessage;
    switch (props.elementType) {
        case ('input'):
            inputElement = (
                <TextField
                    error={props.invalid}
                    fullWidth
                    variant="outlined"
                    {...props.elementConfig}
                    value={props.value}
                    helperText={helperText}
                    onChange={props.changed}
                />
            );
            break;
        case ('password'):
            inputElement = (
                <TextField
                    type="password"
                    error={props.invalid}
                    fullWidth
                    variant="outlined"
                    {...props.elementConfig}
                    value={props.value}
                    helperText={helperText}
                    onChange={props.changed}
                />
            );
            break;
        case ('checkbox'):
            inputElement = (
                <>
                    <Checkbox
                        inputProps={{'aria-label': 'Is Trial'}}
                        {...props.elementConfig}
                        value={props.value}
                        onChange={props.changed}
                    />
                    <label>{props.elementConfig.label}</label>
                </>
            )
            break;
        case ("date"):
            inputElement = (
                <DatePicker
                    value={props.value}
                    elementConfig={props.elementConfig}
                    changed={props.changed}
                />
            );
            break;
        case ("drop-down"):
            inputElement = (
                <DropDown
                    value={props.value}
                    elementConfig={props.elementConfig}
                    changed={props.changed}
                />
            );
            break;
        case ("option"):
            inputElement = (
                <RadioButtonGroup
                    value={props.value}
                    elementConfig={props.elementConfig}
                    changed={props.changed}
                />
            );
            break;
        default:
            inputElement = (<TextField
                fullWidth
                variant="outlined"
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
            />)
            break;
    }
    return (
        <>
            {inputElement}
        </>
    )
};

export default Input;