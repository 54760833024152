export let checkValidity = (value, rules) => {
    let errorMessage = '';
    if (rules) {
        if (rules.required) {

            if (typeof value === "string" && value.trim() === '') {
                errorMessage = "Required Field";
            }
        }
        if (rules.minLength) {
            if (value.length < rules.minLength) {
                errorMessage = "Min length " + rules.minLength;
            }
        }
        if (rules.maxLength) {
            if (value.length > rules.maxLength) {
                errorMessage = "Max length " + rules.maxLength;
            }
        }
        if (rules.minDate) {
            if (value < rules.minDate) {
                errorMessage = "The date can't be less than minimal date";
            }
        }
        if (rules.maxDate) {
            if (value > rules.maxDate) {
                errorMessage = "The date can't be bigger than minimal date";
            }
        }
    }
    return errorMessage;
};

export let inputChangedHandler = (controls, event, inputIdentifier) => {
    let updatedLiecenseForm = {
        ...controls
    };
    let updatedFormElement = {
        ...updatedLiecenseForm[inputIdentifier]
    };
    if (updatedFormElement.elementType === "checkbox") {
        updatedFormElement.value = event.target.checked
    } else {
        updatedFormElement.value = event.target.value
    }
    let errorMessage = checkValidity(updatedFormElement.value, updatedFormElement.validation)
    updatedFormElement.valid = errorMessage === '';
    updatedFormElement.errorMessage = errorMessage;
    updatedLiecenseForm[inputIdentifier] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updatedLiecenseForm) {
        formIsValid = updatedLiecenseForm[inputIdentifier].valid && formIsValid
    }
    return {controls: updatedLiecenseForm, formIsValid: formIsValid};
};

export let dateStrToShortDate = (dateInt) => {
    let parsedDate = new Date(parseInt(dateInt) * 1000);
    return parsedDate.toLocaleString().split(',')[0]
};