import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

let initialState = {
    license: null,
    error: false,
    loading: false,
    submitted: false
};

let createLicenseStart = (state, action) => {
    return updateObject(state, {
        license: null,
        loading: true,
        error: false,
        submitted: false
    });
};

let createLicenseInit = (state, action) => {
    return updateObject(state, {
        license: null,
        error: false,
        loading: true,
        submitted: false
    });
};

let createLicenseSuccess = (state, action) => {
    return updateObject(state, {
        license: action.licenseData,
        submitted: action.submitted,
        loading: false,
        error: false
    });
};

let createLicenseFail = (state, action) => {
    return updateObject(state, {
        license: null,
        loading: action.loading,
        error: action.error,
        submitted: action.submitted
    });
};

let fetchLicenseStart = (state, action) => {
    return updateObject(state, {
        loading: action.loading,
        error: action.error,
        license: null,
        submitted: false
    });
};

let setLicense = (state, action) => {
    return updateObject(state, {
        license: action.license,
        loading: action.loading,
        error: action.error,
        submitted: false
    });
};

let fetchLicenseFailed = (state, action) => {
    return updateObject(state, {
        license: null,
        loading: action.loading,
        error: action.error,
        submitted: false
    });
};

let resetLicense = (state, action) => {
    return updateObject(state, initialState);
};

const license = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_LICENSE_START:
            return createLicenseStart(state, action);
        case actionTypes.CREATE_LICENSE_INIT:
            return createLicenseInit(state, action);
        case actionTypes.CREATE_LICENSE_SUCCESS:
            return createLicenseSuccess(state, action);
        case actionTypes.CREATE_LICENSE_FAIL:
            return createLicenseFail(state, action);
        case actionTypes.FETCH_LICENSE_START:
            return fetchLicenseStart(state, action);
        case actionTypes.SET_LICENSE:
            return setLicense(state, action);
        case actionTypes.FETCH_LICENSE_FAILED:
            return fetchLicenseFailed(state, action);
        case actionTypes.RESET_LICENSE:
            return resetLicense(state, action);
        default:
            return state
    }
};

export default license