import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function RadioButtonsGroup(props) {
    let options = props.elementConfig.options.map(option =>
        <FormControlLabel key={option.key} value={option.key} control={<Radio/>} label={option.value}/>
    );

    return (

        <FormControl component="fieldset">
            <FormLabel component="legend">License Type</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={props.value} onChange={props.changed}>
                {options}
            </RadioGroup>
        </FormControl>
    );
}