import * as actionTypes from './actionTypes';
import axios from "../../axios-accounts";

export let setAccounts = (accounts) => {
    return {
        type: actionTypes.SET_ACCOUNTS,
        accounts: accounts
    };
};

export let fetchAccountsFailed = (error) => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_FAILED,
        error: error
    }
}
export let initAccounts = () => {
    return dispatch => {
        axios.get("/accounts")
            .then(response => {
                dispatch(setAccounts(response.data))
            })
            .catch(error => {
                dispatch(fetchAccountsFailed(error.message))
            });
    }
};