import ReactDOM from 'react-dom';
import App from './components/App';
import {applyMiddleware, combineReducers, compose as reduxCompose, createStore} from "redux";
import licenseReducer from "./store/reducers/license";
import licensesReducer from "./store/reducers/licenses";
import authReducer from "./store/reducers/auth";
import accountsReducer from "./store/reducers/accounts";

import thunk from "redux-thunk";
import {Provider} from "react-redux";
import React from "react";
import {BrowserRouter} from "react-router-dom";
import accountReducer from "./store/reducers/account";

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose;
let rootReducer = combineReducers({
    license: licenseReducer,
    auth: authReducer,
    accounts: accountsReducer,
    account: accountReducer,
    licenses: licensesReducer
});

let store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

