import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import admins from '../../admins';

let initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    email: null,
    isAdmin: false
};

let authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        token: null,
        userId: null,
        email: null,
        isAdmin: false
    });
};

let authSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        token: action.authData.token,
        userId: action.authData.userId,
        email: action.authData.email,
        isAdmin: admins.includes(action.authData.email)
    });
};

let authFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        token: null,
        userId: null,
        email: null,
        isAdmin: false
    });
};

let authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        userId: null,
        expirationDate: null,
        email: null,
        isAdmin: false
    });
};

let reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.AUTH_START):
            return authStart(state, action);
        case (actionTypes.AUTH_SUCCESS):
            return authSuccess(state, action);
        case (actionTypes.AUTH_FAIL):
            return authFail(state, action);
        case (actionTypes.AUTH_LOGOUT):
            return authLogout(state, action);
        default:
            return state;
    }
};

export default reducer;