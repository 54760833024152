import React, {Component} from 'react';

import ButtonAppBar from './ButtonAppBar'
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import theme from '../theme'
import {connect} from 'react-redux';
import {ThemeProvider} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import Licenses from './Licenses'
import axios from 'axios'
import NewLicense from "./NewLicense";
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose';
import Auth from "./Auth";
import * as actions from '../store/actions'
import AccountsManager from "./AccountsManager";
import AccountDetails from "./AccountDetails";
import LicenseDetails from "./LicennseDetails";

axios.defaults.headers.post['Content-Type'] = "application/json"

let styles = theme => ({
    helloThereStyle: {
        fontStyle: 'oblique'
    }
});

class App extends Component {
    state = {
        "auth": false
    }

    componentDidMount() {
        this.props.onTryAutoSignup()
    }

    render() {
        let app = (
            <Switch>
                <Route path="/auth" exact component={Auth}/>
                <Redirect to="/auth"/>
            </Switch>
        )
        if (this.props.isAuthenticated) {
            app = (<Grid container direction="column" spacing={2}>
                <Grid item>
                    <ButtonAppBar/>
                </Grid>
                <Grid item container>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={8}>
                        <Switch>
                            <Route path="/licenses" exact component={Licenses}/>
                            <Route path="/licenses/new" exact component={NewLicense}/>
                            <Route path="/accounts/:accountId/licenses/new" exact component={NewLicense}/>
                            <Route path="/accounts/:accountId/licenses/:id" exact component={LicenseDetails}/>
                            <Route path="/accounts" exact component={AccountsManager}/>
                            <Route path="/accounts/:id" exact component={AccountDetails}/>
                        </Switch>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </Grid>);
        }

        return (
            <ThemeProvider theme={theme}>
                {app}
            </ThemeProvider>
        );
    }

}

let mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    }
};

let mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
};

export default compose(
    withStyles(styles()),
    connect(mapStateToProps, mapDispatchToProps)
)(App);
