import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";

let initialState = {
    accounts: null,
    error: false,
    loading: false
};

let setAccounts = (state, action) => {
    return updateObject(state, {
        accounts: action.accounts,
        error: false,
        loading: false
    });
};

let fetchAccountsFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
};

let fetchAccountsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: false
    })
};

let fetchAccountsInit = (state, action) => {
    return updateObject(state, {
        error: false,
        loading: false
    })
};

let accounts = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACCOUNTS:
            return setAccounts(state, action);
        case actionTypes.FETCH_ACCOUNTS_FAILED:
            return fetchAccountsFailed(state, action);
        case actionTypes.FETCH_ACCOUNTS_START:
            return fetchAccountsStart(state, action);
        case actionTypes.FETCH_ACCOUNTS_INIT:
            return fetchAccountsInit(state, action);

        default:
            return state;
    }
};

export default accounts;