export let ADD_INGREDIENT = "ADD_INGREDIENT";
export let REMOVE_INGREDIENT = "REMOVE_INGREDIENT";
export let FETCH_LICENSES_START = "FETCH_LICENSES_START"
export let FETCH_LICENSES = "FETCH_LICENSES";
export let FETCHED_LICENSES = "FETCHED_LICENSES";

export let SET_LICENSES = "SET_LICENSES";
export let FETCH_LICENSES_FAILED = "FETCH_LICENSES_FAILED";
export let CREATE_LICENSE_SUCCESS = "CREATE_LICENSE_SUCCESS";
export let CREATE_LICENSE_FAIL = "CREATE_LICENSE_FAIL";
export let CREATE_LICENSE_START = "CREATE_LICENSE_START";
export let CREATE_LICENSE_INIT = "CREATE_LICENSE_INIT";

export let FETCH_LICENSE_START = "FETCH_LICENSE_START";
export let SET_LICENSE = "SET_LICENSE";
export let FETCH_LICENSE_FAILED = "FETCH_LICENSE_FAILED";

export let AUTH_START = "AUTH_START";
export let AUTH_SUCCESS = "AUTH_SUCCESS";
export let AUTH_FAIL = "AUTH_FAIL";
export let AUTH_LOGOUT = "AUTH_LOGOUT";


export let SET_ACCOUNTS = "SET_ACCOUNTS";
export let FETCH_ACCOUNTS_FAILED = "FETCH_ACCOUNTS_FAILED";
export let FETCH_ACCOUNTS_START = "FETCH_ACCOUNTS_START";
export let FETCH_ACCOUNTS_INIT = "FETCH_ACCOUNTS_INIT";


export let SET_ACCOUNT = "SET_ACCOUNT";
export let FETCH_ACCOUNT_INIT ="FETCH_ACCOUNT_INIT";
export let FETCH_ACCOUNT_START = "FETCH_ACCOUNT_START";
export let FETCH_ACCOUNT_FAILED = "FETCH_ACCOUNT_FAILED";


export let CREATE_ACCOUNT_START = "CREATE_ACCOUNT_START";
export let CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export let CREATE_ACCOUNT_FAIL = "CREATE_ACCOUNT_FAIL";

export let FETCH_ACCOUNT_LICENSES_START = "FETCH_ACCOUNT_LICENSES_START";
export let SET_ACCOUNT_LICENSES = "SET_ACCOUNT_LICENSES";
export let FETCH_ACCOUNT_LICENSES_FAILED = "FETCH_ACCOUNT_LICENSES_FAILED";

export let RESET_LICENSE = "RESET_LICENSE";

export let RESET_NEW_ACCOUNT = "RESET_NEW_ACCOUNT";