import React, {Component} from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {compose} from "recompose";
import {connect} from "react-redux";
import Input from '../containers/UI/Input';
import * as actions from '../store/actions';
import Container from '@material-ui/core/Container';
import Loader from "../containers/Loader";
import Alert from '@material-ui/lab/Alert';

const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class Auth extends Component {
    state = {
        controls: {
            username: {
                elementType: "input",
                elementConfig: {
                    type: "text",
                    label: "Username",
                    margin: "normal"
                },
                value: '',
                validation: {
                    required: true
                },
                valid: true,
                errorMessages: []
            },
            password: {
                elementType: "input",
                elementConfig: {
                    type: "password",
                    label: "Password",
                    margin: "normal"
                },
                value: '',
                validation: {
                    required: true
                },
                valid: true,
                errorMessages: []
            }
        }
    }

    onSubmitHandler = (event) => {
        event.preventDefault();
        this.props.onAuth(this.state.controls.username.value, this.state.controls.password.value)
    };

    checkValidity(value, rules) {
        let errorMessage = []
        if (rules) {
            if (rules.required) {
                if (value.trim() === '') {
                    errorMessage.push("Required Field")
                    errorMessage.push("Required Field")
                }
            }
            if (rules.minLength) {
                if (value.length < rules.minLength) {
                    errorMessage.push("Min length " + rules.minLength)
                }
            }
            if (rules.maxLength) {
                if (value.length > rules.maxLength) {
                    errorMessage.push("Max length " + rules.maxLength)
                }
            }
        }
        return errorMessage;
    }

    inputChangedHandler = (event, inputIdentifier) => {
        let updatedLiecenseForm = {
            ...this.state.controls
        };
        let updatedFormElement = {
            ...updatedLiecenseForm[inputIdentifier]
        };
        if (updatedFormElement.elementType === "checkbox") {
            updatedFormElement.value = event.target.checked
        } else {
            updatedFormElement.value = event.target.value
        }
        let errorMessages = this.checkValidity(updatedFormElement.value, updatedFormElement.validation)
        updatedFormElement.valid = errorMessages.length === 0;
        updatedFormElement.errorMessages = errorMessages;
        updatedLiecenseForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true
        for (let inputIdentifier in updatedLiecenseForm) {
            formIsValid = updatedLiecenseForm[inputIdentifier].valid && formIsValid
        }
        this.setState({controls: updatedLiecenseForm, formIsValid: formIsValid})
    }

    render() {
        let classes = this.props.classes;
        const formElementArray = []
        for (let key in this.state.controls) {
            formElementArray.push({
                id: key,
                config: this.state.controls[key]
            })
        }
        const form = formElementArray.map(formElement => (
            <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                changed={(event) => this.inputChangedHandler(event, formElement.id)}
                invalid={!formElement.config.valid}
                errorMessages={formElement.config.errorMessages}
            />
        ));
        let loader = null;
        if (this.props.loading) {
            loader = <Loader/>
        }
        let error = null;
        if (this.props.error) {
            error = <Alert severity="error">{this.props.error}</Alert>
        }
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    {loader}
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate>
                        {form}
                        {error}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(event) => this.onSubmitHandler(event)}
                        >
                            Sign In
                        </Button>
                    </form>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password) => dispatch(actions.auth(username, password))
    }
};
export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Auth);