import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

let initialState = {
    account: null,
    error: false,
    loading: false,
    submitted: false
};

let createAccountStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: false
    })
};

let createAccountSuccess = (state, action) => {
    return updateObject(state, {
        accountData: action.accountData,
        submitted: action.submitted,
        loading: action.loading,
        error: action.error
    })
};

let createAccountFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        submitted: action.submitted,
        loading: action.loading
    });
};

let fetchAccountStart = (state, action) => {
    return updateObject(state, {
        loading: action.loading,
        error: action.error
    });
};

let setAccount = (state, action) => {
    return updateObject(state, {
        account: action.account,
        loading: action.loading
    });
};

let fetchAccountFailed = (state, action) => {
    return updateObject(state, {
        loading: action.loading,
        error: action.error
    })
};

let resetNewAccount = (state, action) => {
    console.log("initialState: ", initialState)
    return updateObject(state,
        initialState
    );
};

let account = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_ACCOUNT_START:
            return createAccountStart(state, action);
        case actionTypes.CREATE_ACCOUNT_SUCCESS:
            return createAccountSuccess(state, action);
        case actionTypes.CREATE_ACCOUNT_FAIL:
            return createAccountFail(state, action);
        case actionTypes.FETCH_ACCOUNT_START:
            return fetchAccountStart(state, action);
        case actionTypes.SET_ACCOUNT:
            return setAccount(state, action);
        case actionTypes.FETCH_ACCOUNT_FAILED:
            return fetchAccountFailed(state, action);
        case actionTypes.RESET_NEW_ACCOUNT:
            return resetNewAccount(state, action);
        default:
            return state;
    }
};

export default account;