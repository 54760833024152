import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import {compose} from "recompose";
import {connect} from "react-redux";
import {onLogoutInnit} from "../store/actions";
import {withRouter} from "react-router";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class ButtonAppBar extends Component {
    onLicensesHandler = () => {
        this.props.history.push("/licenses")
    };

    onAccountsHandler = () => {
        this.props.history.push("/accounts")
    };

    render() {
        let classes = this.props.classes
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon/>
                        </IconButton>
                        <Grid>
                            <Typography variant="h6" className={classes.title}>
                                Cloudify Platform
                            </Typography>
                        </Grid>
                        {/*<Button onClick={() => this.onLicensesHandler()}>Licenses</Button>*/}
                        <Button onClick={() => this.onAccountsHandler()}>Accounts</Button>
                        <Button onClick={() => this.props.onSignoutInit()} color="inherit">Logout</Button>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

let mapDispatchToProps = dispatch => {
    return {
        onSignoutInit: () => dispatch(onLogoutInnit())
    }
}
export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
    withRouter
)(ButtonAppBar)