import React, {Component} from "react";
import {Typography, Grid} from "@material-ui/core";
import * as accountActions from "../store/actions";
import {compose} from "recompose";
import {connect} from "react-redux";
import Loader from "../containers/Loader";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router";
import LicensesTable from "../containers/LicensesTable";
import {dateStrToShortDate} from "../utility";
import {withStyles} from "@material-ui/core/styles";

let styles = (theme) => ({
    licencesButtonPanel: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
});


class AccountDetails extends Component {
    componentDidMount() {
        let accountId = this.props.match.params["id"];
        if (accountId) {
            this.props.onInitAccount(accountId);
            this.props.onInitLicenses(accountId);
        }
    }

    addLicenseHandler = (accountId) => {
        this.props.onResetLicense();
        this.props.history.push("/accounts/" + accountId + "/licenses/new");
    }

    addCSVHandler = () => {
        const rows = [
            ["name1", "city1", "some other info"],
            ["name2", "city2", "more info"]
        ];
        let header = ["Customer ID", "Version", "Edition", "Expiration Date", "Is Trial", "Created By", "Created At", "Updated At"];
        let content = "";
        if (this.props.licenses) {
            content = this.props.licenses.map(
                license => [
                    license.customerId,
                    license.cloudifyVersion,
                    license.licenseEdition,
                    license.expirationDate,
                    license.trial,
                    license.createdBy,
                    dateStrToShortDate(license.createdAt),
                    dateStrToShortDate(license.updatedAt)
                ].join(",")
            ).join("\n");
        }
        let csvContent =
            + header.join(",") + "\n"
            + content;
        let link = document.createElement("a");
        let blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "licenses.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        let classes = this.props.classes;
        let account = <Loader/>;
        if (this.props.loading) {
            account = <Loader/>
        }

        let licenses = <Loader/>;

        if (this.props.account) {
            licenses = <LicensesTable
                licenses={this.props.licenses}
                onLicenseClick={(licenseId) => this.props.history.push("/accounts/" + this.props.account.id + "/licenses/" + licenseId)}
            />

            account = (
                <Grid container direction="column">
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Box fontWeight="fontWeightBold">
                                ID
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>{this.props.account.id}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Box fontWeight="fontWeightBold">
                                Name
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{this.props.account.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Box fontWeight="fontWeightBold">
                                Updated At
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{dateStrToShortDate(this.props.account.updatedAt)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Box fontWeight="fontWeightBold">
                                Created At
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{dateStrToShortDate(this.props.account.createdAt)}</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="h4">Licenses</Typography>
                    <Grid container direction="row" spacing={8}>
                        <Grid item className={classes.licencesButtonPanel}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.addLicenseHandler(this.props.account.id)}
                            >
                                Add License
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => this.addCSVHandler()}
                            >
                                CSV
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        {licenses}
                    </Grid>
                </Grid>)
        }

        return (
            <>
                {account}
            </>
        );
    }
};

let mapStateToProps = state => {
    return {
        account: state.account.account,
        loading: state.account.loading,
        licenses: state.licenses.licenses,
        error: state.account.error,
        isAdmin: state.auth.isAdmin
    };
};

let mapDispatchToProps = dispatch => {
    return {
        onInitAccount: (accountId) => dispatch(
            accountActions.initAccount(accountId)
        ),
        onInitLicenses: (accountId) => dispatch(
            accountActions.initAccountLicenses(accountId)
        ),
        onResetLicense: () => dispatch(
            accountActions.resetLicense()
        )
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(AccountDetails);