import axios_account from "../../axios-accounts";
import axios from "../../axios-licenses";
import * as actionTypes from "./actionTypes";

export let initAccountLicenses = (accountId) => {
    return dispatch => {
        dispatch(fetchLicensesStart());
        axios_account.get("/accounts/" + accountId + "/licenses")
            .then(response => {
                    dispatch(setLicenses(response.data))
            })
            .catch(error => {
             dispatch(fetchLicensesFailed(error.message))
            });
    }
};

export let initLicenses = () => {
    return dispatch => {
        dispatch(fetchLicensesStart())
        axios.get("/license")
            .then(response => {
                dispatch(setLicenses(response.data))
            })
            .catch(error => {
                dispatch(fetchLicensesFailed(error.message))
            });
    };
};

export let fetchLicensesStart = () => {
    return {
        type: actionTypes.FETCH_LICENSES_START,
        loading: true,
        error: false
    }
};

export let setLicenses = (licenses) => {
    return {
        type: actionTypes.SET_LICENSES,
        licenses: licenses,
        submitted: false
    }
};


export let fetchLicensesFailed = (error) => {
    return {
        type: actionTypes.FETCH_LICENSES_FAILED,
        error: error
    };
};
