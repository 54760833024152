import * as actionTypes from './actionTypes';
import axios from "../../axios-licenses";


export let resetLicense = (error) => {
    return{
        type: actionTypes.RESET_LICENSE
    }
};

export let createLicenseFail = (error) => {
    return {
        type: actionTypes.CREATE_LICENSE_FAIL,
        error: error,
        submitted: false
    };
};

export let createLicenseSuccess = (id, licenseData) => {
    return {
        type: actionTypes.CREATE_LICENSE_SUCCESS,
        licenseId: id,
        licenseData: licenseData,
        submitted: true
    };
};


export let createLicenseStart = () => {
    return {
        type: actionTypes.CREATE_LICENSE_START
    }
};

export let createLicense = (accountId, licenseData) => {
    return dispatch => {
        dispatch(createLicenseStart());
        axios.post("/accounts/" + accountId + "/licenses", licenseData)
            .then(
                response => {
                    dispatch(createLicenseSuccess(response.data.id, response.data))
                },
                error => {
                    dispatch(createLicenseFail(error))
                }
            )
    };
};

export let createLicenseInit = () => {
    return {
        type: actionTypes.CREATE_LICENSE_INIT
    };
};

export let fetchLicenseStart = () => {
    return {
        type: actionTypes.FETCH_LICENSE_START,
        loading: true,
        error: false
    };
};

export let setLicense = (license) => {
    return {
        type: actionTypes.SET_LICENSE,
        license: license,
        loading: false,
        error: false
    };
};

export let fetchLicenseFailed = (error) => {
  return {
      type: actionTypes.FETCH_LICENSE_FAILED,
      loading: false,
      error: error,
      license: null
  }
};

export let initLicense = (accountId, id) => {
    return dispatch => {
        dispatch(fetchLicenseStart());
        axios.get("/accounts/" + accountId + "/licenses/" + id)
            .then(
                response => {
                    dispatch(setLicense(response.data));
                }
            )
            .catch(
                error => {
                    dispatch(fetchLicenseFailed(error.message));
                }
            );
    };
};