import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

let DatePicker = (props) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                fullWidth
                format="MM/dd/yyyy"
                value={props.value}
                onChange={(event) => props.changed({target:{value:event}})}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                {...props.elementConfig}
            />
        </MuiPickersUtilsProvider>
    );
};

export default DatePicker;