import React, {Component} from 'react'
import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Loader from "../containers/Loader";
import Box from "@material-ui/core/Box";
import {Typography, Grid} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';

import * as licenseActions from '../store/actions';
import {dateStrToShortDate} from "../utility";

class LicenseDetails extends Component {
    componentDidMount() {
        let licenseId = this.props.match.params["id"];
        let accountId = this.props.match.params["accountId"]
        if(licenseId && accountId) {
            this.props.onInitLicense(accountId, licenseId)
        }
    }

    render() {
        let license = null;
        if (this.props.loading) {
            license = <Loader/>
        }
        if(this.props.license) {
            license = (
                <Grid container direction="column">
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Typography>
                                <Box fontWeight="fontWeightBold">
                                    ID
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{this.props.license.id}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Typography>
                                <Box fontWeight="fontWeightBold">
                                    Version
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{this.props.license.cloudifyVersion}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Typography>
                                <Box fontWeight="fontWeightBold">
                                    Customer ID
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{this.props.license.customerId}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Typography>
                                <Box fontWeight="fontWeightBold">
                                    License Edition
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{this.props.license.licenseEditionn}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Typography>
                                <Box fontWeight="fontWeightBold">
                                    Is Trial
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{this.props.license.trial}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Typography>
                                <Box fontWeight="fontWeightBold">
                                    Expiration Date
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{this.props.license.expirationDate}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Typography>
                                <Box fontWeight="fontWeightBold">
                                    Created By
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{this.props.license.createdBy}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Typography>
                                <Box fontWeight="fontWeightBold">
                                    Created At
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{dateStrToShortDate(this.props.license.createdAt)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Typography>
                                <Box fontWeight="fontWeightBold">
                                    Updated At
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{dateStrToShortDate(this.props.license.updatedAt)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={2}>
                            <Typography>
                                <Box fontWeight="fontWeightBold">
                                    Signature
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                              id="outlined-multiline-static"
                              multiline
                              fullWidth
                              rows={15}
                              value={this.props.license.license_signature}
                              variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        return (
            <>
                {license}
            </>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        license: state.license.license,
        loading: state.license.loading,
        error: state.license.error
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        onInitLicense: (accountId, licenseId) => dispatch(
            licenseActions.initLicense(accountId, licenseId)
        )
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(LicenseDetails);