import * as actionTypes from './actionTypes';
import axios from '../../axios-accounts';

export let createAccount = (accountData) => {
    return dispatch => {
        dispatch(createAccountStart());
        axios.post("/accounts", accountData)
            .then(response => {
                dispatch(createAccountSuccess(response.data))
            })
            .catch(error => {
               dispatch(createAccountFail(error.message))
            });
    }
};

export let createAccountStart = () => {
    return {
        type: actionTypes.CREATE_ACCOUNT_START
    }
};


export let createAccountSuccess = (accountData) => {
    return {
        type: actionTypes.CREATE_ACCOUNT_SUCCESS,
        accountData: accountData,
        submitted: true,
        loading: false,
        error: false
    }
};

export let createAccountFail = (error) => {
    return {
        type: actionTypes.CREATE_ACCOUNT_FAIL,
        error: error,
        submitted: true,
        loading: false
    }
};

export let initAccount = (id) => {
    return dispatch => {
        dispatch(fetchAccountStart());
        axios.get("/accounts/" + id)
            .then(response => {
                dispatch(setAccount(response.data));
            })
            .catch(error => {
                dispatch(fetchAccountFailed(error.message));
            });
    };
};

export let fetchAccountStart = () => {
  return {
      type: actionTypes.FETCH_ACCOUNT_START,
      loading: true,
      error: false
  }
};

export let setAccount = (accountData) => {
    return {
        type: actionTypes.SET_ACCOUNT,
        account: accountData,
        loading: false
    }
};

export let fetchAccountFailed = (error) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_FAILED,
        loading: false,
        error: error
    }
};

export let resetNewAccount = () => {
    return {
        type: actionTypes.RESET_NEW_ACCOUNT
    }
}