import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {inputChangedHandler} from "../utility";
import {compose} from "recompose";
import {connect} from "react-redux";
import * as accountActions from '../store/actions/account';
import Input from "../containers/UI/Input";
import Loader from "../containers/Loader";
import Alert from "@material-ui/lab/Alert";
import * as accountsActions from "../store/actions";

let initControlsState = {
    name: {
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Name",
            margin: "normal"
        },
        value: '',
        validation: {
            required: true
        },
        valid: true,
        errorMessage: null
    },
    expirationDate: {
        elementType: "date",
        elementConfig: {
            label: "Expiration Date",
            marin: "normal",
            minDate: new Date(new Date().getTime() + 60 * 60 * 24 * 30 * 1000),
            maxDate: new Date(new Date().getTime() + 60 * 60 * 24 * 365 * 10 * 1000)
        },
        value: new Date(new Date().getTime() + 60 * 60 * 24 * 30 * 1000),
        validation: {
            required: true,
            minDate: new Date(new Date().getTime() + 60 * 60 * 24 * 30 * 1000),
            maxDate: new Date(new Date().getTime() + 60 * 60 * 24 * 365 * 10 * 1000)
        },
        valid: true,
        errorMessage: null
    },
    allowedVersion: {
        elementType: "drop-down",
        elementConfig: {
            label: "Allowed Versions",
            margin: "none",
            options: [
                {key: "5", value: "Any 5"},
                {key: "5.0.5", value: "5.0.5"},
                {key: "5.1", value: "5.1"},
                {key: "5.1.1", value: "5.1.1"}
            ],
        },
        value: '',
        valid: true,
        errorMessage: null,
    },
    trialOnly: {
        elementType: "checkbox",
        elementConfig: {
            label: "Trial Only",
            margin: "normal",
        },
        value: true,
        valid: true,
        errorMessage: null
    },
    customerId: {
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Customer ID",
            margin: "normal"
        },
        validation: {
            required: true
        },
        value: '',
        valid: true,
        errorMessage: null
    },
    allowedLicenseEdition: {
        elementType: "drop-down",
        elementConfig: {
            label: "Allowed License Edition",
            margin: "none",
            options: [
                {key: "Premium", value: "Premium"},
                {key: "Spire", value: "Spire"}
            ],
        },
        value: "Premium",
        valid: true,
        errorMessage: null
    }
};

class NewAccount extends Component {
    state = {
        controls: {
            name: {
                elementType: "input",
                elementConfig: {
                    type: "text",
                    label: "Name",
                    margin: "normal"
                },
                value: '',
                validation: {
                    required: true
                },
                valid: true,
                errorMessage: null
            },
            expirationDate: {
                elementType: "date",
                elementConfig: {
                    label: "Expiration Date",
                    marin: "normal",
                    minDate: new Date(new Date().getTime() + 60 * 60 * 24 * 30 * 1000),
                    maxDate: new Date(new Date().getTime() + 60 * 60 * 24 * 365 * 10 * 1000)
                },
                value: new Date(new Date().getTime() + 60 * 60 * 24 * 30 * 1000),
                validation: {
                    required: true,
                    minDate: new Date(new Date().getTime() + 60 * 60 * 24 * 30 * 1000),
                    maxDate: new Date(new Date().getTime() + 60 * 60 * 24 * 365 * 10 * 1000)
                },
                valid: true,
                errorMessage: null
            },
            allowedVersion: {
                elementType: "drop-down",
                elementConfig: {
                    label: "Allowed Versions",
                    margin: "none",
                    options: [
                        {key: "5", value: "Any 5"},
                        {key: "5.0.5", value: "5.0.5"},
                        {key: "5.1", value: "5.1"},
                        {key: "5.1.1", value: "5.1.1"}
                    ],
                },
                value: '',
                valid: true,
                errorMessage: null,
            },
            trialOnly: {
                elementType: "checkbox",
                elementConfig: {
                    label: "Trial Only",
                    margin: "normal",
                },
                value: true,
                valid: true,
                errorMessage: null
            },
            customerId: {
                elementType: "input",
                elementConfig: {
                    type: "text",
                    label: "Customer ID",
                    margin: "normal"
                },
                validation: {
                    required: true
                },
                value: '',
                valid: true,
                errorMessage: null
            },
            allowedLicenseEdition: {
                elementType: "drop-down",
                elementConfig: {
                    label: "Allowed License Edition",
                    margin: "none",
                    options: [
                        {key: "Premium", value: "Premium"},
                        {key: "Spire", value: "Spire"}
                    ],
                },
                value: "Premium",
                valid: true,
                errorMessage: null
            }
        },
        formIsValid: false
    };

    resetControlsToDefaultValues = () => {
        this.setState({controls: initControlsState});
    }

    inputChangeHandler = (event, inputIdentifier) => {
        this.setState(() => inputChangedHandler(this.state.controls, event, inputIdentifier));
    };

    onSubmitHandle = (event) => {
        event.preventDefault();
        let account = {
            name: this.state.controls.name.value,
            expirationDate: this.state.controls.expirationDate.value,
            version: this.state.controls.allowedVersion.value,
            trialOnly: this.state.controls.trialOnly.value,
            customerId: this.state.controls.customerId.value,
            licenseEdition: this.state.controls.allowedLicenseEdition.value
        };
        this.props.onCreateAccount(account)
    };

    render() {
        if (this.props.submitted) {
            this.props.handleClose();
            this.resetControlsToDefaultValues();
            this.props.onRestNewAccount();
        }
        let formElementArray = [];
        for (let key in this.state.controls) {
            formElementArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }
        let form = formElementArray.map(formElement => (

            <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                changed={(event) => this.inputChangeHandler(event, formElement.id)}
                invalid={!formElement.config.valid}
                errorMessage={formElement.config.errorMessage}
            />
        ));
        let loader = null;
        if (this.props.loading) {
            loader = <Loader/>
        }
        let error = null;
        if (this.props.error) {
            error = <Alert severity="error">{this.props.error}</Alert>
        }
        return (
            <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
                {loader}
                <DialogTitle id="form-dialog-title">New Account</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Account details to be created
                    </DialogContentText>
                    {form}
                    {error}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={(event) => this.onSubmitHandle(event)}
                        color="primary"
                        disabled={!this.state.formIsValid}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

let mapStateToProps = state => {
    return {
        account: state.account.account,
        error: state.account.error,
        submitted: state.account.submitted,
        loading: state.account.loading
    }
};

let mapDispatchToProps = dispatch => {
    return {
        onCreateAccount: (accountData) => dispatch(
            accountActions.createAccount(accountData)
        ),
        onRestNewAccount: () => dispatch(
            accountsActions.resetNewAccount()
        )
    };
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(NewAccount);